import React, { useEffect, useRef } from "react";
import Message from "../message";
import s from "./index.module.css";
import Input from "../input";
import styled from "styled-components";

import btn from "../../assets/close-button.png";

const CloseChatButtonStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

const CloseChatButton = ({ onClick }) => (
  <CloseChatButtonStyled onClick={onClick} title="Zamknij okno czata">
    <img width="100%" src={btn} alt="close chat" />
  </CloseChatButtonStyled>
);

const closeChatWindow = () => {};

const ChatWindow = ({ messages, users, newMessage, newFile, me, usersMap }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current.scrollTo(0, ref.current.scrollHeight);
  }, [messages]);

  return (
    <div className={s.chatWindow}>
      <CloseChatButton onClick={closeChatWindow} />
      <div className={s.messages} ref={ref}>
        {messages.map((msg) => (
          <Message key={msg.id} {...msg} me={me} usersMap={usersMap}></Message>
        ))}
      </div>

      <Input users={users} newMessage={newMessage} newFile={newFile} />
    </div>
  );
};

export default ChatWindow;
