import React, { useState } from "react";
import s from "./index.module.css";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { CircleProgress } from "react-gradient-progress";

const BACKEND = process.env.REACT_APP_BACKEND_ADDRESS;

const Input = ({ newMessage, newFile, users }) => {
  const [text, setText] = useState("");
  const [sendTo, setSendTo] = useState({});
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);

  const keyup = (event) => {
    if (event.which === 13) {
      if (text.length > 0) {
        newMessage({
          message: text,
          ...(sendTo ? { sendTo } : {}),
        });
        setText("");
      }
    }
  };

  const uploadFile = async (event) => {
    const axiosInstance = axios.create({
      baseURL: BACKEND,
    });
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    setProgress(0);
    setUploading(true);
    const token = axios.CancelToken.source();
    setCancelTokenSource(token);
    try {
      const result = await axiosInstance.post(`/upload_file`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(progress);

          if (progressEvent.loaded === progressEvent.total) {
            setUploading(false);
          }
        },
        cancelToken: token.token,
      });
      if (result.status === 200) {
        newFile({
          fileId: result.data,
          ...(sendTo.userId ? { sendTo } : {}),
        });
      }
    } catch (e) {
      setUploading(false);
    }
  };

  return (
    <div className={s.input}>
      <div className={s.header}>
        <div>
          Do:{" "}
          <select
            className={s.select}
            onChange={(e) => {
              if (e.target.value === "") {
                setSendTo({});
              } else {
                const selectedId = parseInt(e.target.value, 10);

                setSendTo({
                  userId: selectedId,
                  userName: users.find((f) => f.userId === selectedId).userName,
                });
              }
            }}
          >
            <option value="">Wszystkich</option>
            {/*{(users || []).map((u) => (*/}
            {/*  <option key={u.userId} value={u.userId}>*/}
            {/*    {u.userName}*/}
            {/*  </option>*/}
            {/*))}*/}
          </select>
        </div>

        <div>
          {uploading ? (
            <>
              <CircleProgress
                percentage={progress}
                strokeWidth={3}
                width={50}
              />
              <div>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    cancelTokenSource.cancel();
                  }}
                >
                  Anuluj
                </a>
              </div>
            </>
          ) : (
            <>
              <label
                htmlFor="file-upload"
                className={s.fileUpload}
                title="Wyślij plik"
              >
                <FontAwesomeIcon icon={faUpload} />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={uploadFile}
                className={s.hidden}
              />
            </>
          )}
        </div>
      </div>
      <input
        value={text}
        onChange={(e) => setText(e.target.value)}
        className={s.inputField}
        type="text"
        placeholder="Napisz wiadomość..."
        onKeyUp={keyup}
      />
    </div>
  );
};

export default Input;
