import "./App.css";
import ChatWindow from "./components/chat-window";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import qs from "qs";
import axios from "axios";

const notifyFrameOfNewMessage = () => {
  if (window.parent) {
    window.parent.postMessage("new-message", "*");
  }
};

const BACKEND = process.env.REACT_APP_BACKEND_ADDRESS;
const CHAT_BACKEND = process.env.REACT_APP_CHAT_BACKEND_ADDRESS;

function App() {
  const search = window.location.search.substring(1);
  const [sock, setSock] = useState();
  // const [connected, setConnected] = useState(false);
  let parsedSearch = qs.parse(search);

  const displayName = parsedSearch.displayName;
  const roomId = parsedSearch.roomId;
  const peerExtId = parsedSearch.peerExtId;
  const authToken = parsedSearch.authToken;
  const [loggedIn, setLoggedIn] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersMap, setUsersMap] = useState([]);
  const [me, setMe] = useState({});
  const [identity, setIdentity] = useState(null);
  const [identityFetchStarted, setIdentityFetchStarted] = useState(false);
  const [identityFetchCompleted, setIdentityFetchCompleted] = useState();

  const fetchMyIdentity = async () => {
    const response = await fetch(`${BACKEND}/identity`);
    // const response = await fetch("/api/identity");
    if (response.status === 200) {
      let userInfo = await response.json();
      setIdentity(userInfo);
    }
  };

  useEffect(() => {
    async function x() {
      await fetchMyIdentity();
      setIdentityFetchCompleted(true);
    }

    if (!identityFetchStarted) {
      setIdentityFetchStarted(true);
      x();
    }
  }, [identityFetchStarted]);

  useEffect(() => {
    if (identity) {
      setLoggedIn(true);
      return;
    }

    const loginWithToken = async () => {
      const token = authToken.replaceAll("/", "");
      try {
        const response = await axios.post("/auth/login-with-auth-token", {
          authToken: token,
        });

        setLoggedIn(true);
      } catch (e) {}
    };

    if (authToken) {
      loginWithToken();
    }
  }, [authToken, identity]);

  useEffect(() => {
    if (loggedIn && !identity) {
      fetchMyIdentity();
    }
  }, [loggedIn, identity]);

  useEffect(() => {
    if (identity && !sock) {
      console.log("xxx opening ws");
      const socket = io(BACKEND, {
        autoConnect: false,
        path: "/ws",
      });
      setSock(socket);
    }
  }, [identity, sock]);

  useEffect(() => {
    if (!sock || !displayName || !peerExtId || !roomId) {
      return;
    }

    const socket = sock;

    socket.auth = {
      userName: displayName,
      userId: peerExtId,
      roomId,
    };

    setMe({
      userName: displayName,
      userId: peerExtId,
    });

    socket.connect();

    socket.on("connect", () => {
      // setConnected(true);
    });

    // socket.onAny((event, ...args) => {
    //   console.log(event, args);
    // });

    // socket.on("disconnect", () => {
    //   setConnected(false);
    // });

    socket.on("data", (data) => {
      const { date, ...rest } = data;

      console.log("xxx ondata", data);

      setMessages((msgs) => [
        ...msgs,
        {
          date: new Date(date),
          ...rest,
        },
      ]);

      notifyFrameOfNewMessage();
    });

    socket.on("private_message", (data) => {
      const { date, ...rest } = data;

      setMessages((messages) => [
        ...messages,
        {
          date: new Date(date),
          ...rest,
        },
      ]);

      notifyFrameOfNewMessage();
    });

    socket.on("users", (users) => {
      setUsers(
        users
          .filter((u) => u.userId !== socket.auth.userId)
          .sort((a, b) => a.userName.localeCompare(b.userName))
      );
      setUsersMap(
        users.reduce((all, curr) => {
          return {
            ...all,
            [curr.userId]: curr,
          };
        }, {})
      );
    });

    socket.on("messages", (messages) => {
      setMessages(
        messages.map((m) => {
          m.date = new Date(m.date);
          return m;
        })
      );
    });

    socket.on("message_updated", (message) => {
      // console.log("xxx message updated received", message);
      setMessages((current) =>
        current.map((m) => {
          if (m.id === message.id) {
            console.log(
              `xxx matched updated message: m.id: ${m.id}, message.id: ${message.id}`
            );
            message.date = new Date(message.date);
            return message;
          }

          return m;
        })
      );
    });

    return () => {};
  }, [sock, displayName, peerExtId, roomId]);

  const [messages, setMessages] = useState([]);

  // setInterval(() => {
  //   console.log("xxx messages", messages);
  // }, 10000);

  const newMessage = ({ message, sendTo }) => {
    if (sock) {
      if (sendTo.userId) {
        sock.emit("private_message", {
          message,
          to: sendTo,
        });
      } else {
        sock.emit("data", {
          message,
        });
      }
    } else {
      // TODO: queue..
    }
  };

  const newFile = ({ fileId, sendTo }) => {
    if (sock) {
      if (sendTo) {
        sock.emit("private_message", {
          fileId,
          to: sendTo,
        });
      } else {
        sock.emit("data", {
          fileId,
        });
      }
    } else {
      // TODO: queue..
    }
  };

  // if (loggedIn) {
  //   return (
  //     <div>
  //       Uzytkownik niezalogowany...
  //     </div>
  //   )
  // }

  return (
    <div className="App">
      <ChatWindow
        me={me}
        users={users}
        usersMap={usersMap}
        messages={messages}
        newMessage={newMessage}
        newFile={newFile}
      />
    </div>
  );
}

export default App;
