import React from "react";
import { format, isToday } from "date-fns";
import s from "./index.module.css";
import cn from "classnames";
import Linkify from "react-linkify";
import styled from "styled-components";

const BACKEND = process.env.REACT_APP_BACKEND_ADDRESS;

const removeFile = async (fileId) => {
  const response = await fetch(`${BACKEND}/file/${fileId}`, {
    method: "DELETE",
  });

  if (response.status === 200) {
    return true;
  }

  return false;
};

const RemoveButton = styled.button`
  border-radius: 7px;
  border: 1px solid black;
  position: absolute;
  right: 3px;
  bottom: 3px;
  background: orange;
  display: none;
  cursor: pointer;
`;

const RemovedMessageInfo = styled.p`
  font-size: small;
  color: gray;
  padding: 0;
  margin: 0;
`;

const MessageStyled = styled.div`
  position: relative;

  &:hover ${RemoveButton} {
    display: block;
  }
`;

const FileMessage = styled.div`
  padding: 10px 20px;
  background-color: #f0eff4;
  border-radius: 15px;
  background-color: ${(props) => (props.mine ? "#e7f1ff" : "")}
  background-color: ${(props) => (props.removedAt ? "#e7f1ff" : "")}
`;

const Message = ({
  date,
  message,
  fileId,
  from,
  isPrivate,
  to,
  me,
  usersMap,
  removedAt,
}) => {
  // console.log("xxx rendering message:", {
  //   date,
  //   message,
  //   fileId,
  //   from,
  //   isPrivate,
  //   to,
  //   me,
  //   usersMap,
  //   removedAt,
  // });
  const isSameDay = isToday(date);
  const dateString = isSameDay
    ? format(date, "HH:mm")
    : format(date, "yyyy-MM-dd HH:mm");

  const mine = from.userId === me.userId;

  const fileName = fileId ? fileId.substring(37) : "";
  const isImage = /(\.jpg|\.jpeg|\.png|\.gif)$/.test(fileName);

  return (
    <MessageStyled className={cn(s.msg, { [s.mine]: mine })}>
      <div className={s.header}>
        {mine ? (
          <div className={s.person}>
            Ja do{" "}
            {isPrivate
              ? usersMap[to]
                ? usersMap[to].userName
                : "-"
              : "wszystkich"}
            {isPrivate && <span className={s.isPrivate}> (bezpośrednia)</span>}
          </div>
        ) : (
          <div className={s.person}>
            {from.userName} do {isPrivate ? "mnie" : "wszystkich"}
            {isPrivate && <span className={s.isPrivate}> (bezpośrednia)</span>}
          </div>
        )}
        <div className={s.date}>{dateString}</div>
      </div>

      {message ? (
        <div className={cn(s.message, { [s.mine]: mine })}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {message}
          </Linkify>
        </div>
      ) : (
        <FileMessage
          className={cn(s.message, { [s.mine]: mine })}
          removedAt={removedAt}
        >
          {isImage && !removedAt ? (
            <img
              className={s.imagePreview}
              src={`${BACKEND}/serve_file/${fileId}`}
            />
          ) : null}

          {!removedAt ? (
            <a href={`${BACKEND}/serve_file/${fileId}`}>[{fileName}]</a>
          ) : null}

          {!removedAt && mine ? (
            <RemoveButton onClick={() => removeFile(fileId)}>Usuń</RemoveButton>
          ) : null}

          {removedAt ? (
            <RemovedMessageInfo>Wiadomość zostala usunięta</RemovedMessageInfo>
          ) : null}
        </FileMessage>
      )}
    </MessageStyled>
  );
};

export default Message;
